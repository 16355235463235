import request from '@/utils/request'
// 获取到导航
export function Nav(order, status) {
  return request({
    url: '/v2/nav/all',
    method: 'get',
    params: {
      order,
      status,
    },
  })
}

// 获取banner
export function Banner(type) {
  return request({
    url: '/v2/banner/all',
    method: 'get',
    params: {
      type,
    },
  })
}

// 获取一条公司信息
export function ComMesOne(id) {
  return request({
    url: '/v2/company/one',
    method: 'get',
    params: {
      id,
    },
  })
}

// 获取指定艺人
export function LimitArt(limit, is_del) {
  return request({
    url: '/v2/artist/limit',
    method: 'get',
    params: {
      limit,
      is_del
    },
  })
}

// 获取指定数量和类型的新闻
export function LimitNew(limit, type, is_del, recommend, order) {
  return request({
    url: '/v2/news/limit',
    method: 'get',
    params: {
      limit,
      type,
      is_del,
      recommend,
      order,
    },
  })
}

// 获取分页新闻类型
export function PageNew(limit, page, is_del, type, recommend) {
  return request({
    url: '/v2/news/page',
    method: 'get',
    params: {
      limit,
      page,
      is_del,
      type,
      recommend,
    },
  })
}
// 获取分页新闻
export function pageNews(limit, page, is_del) {
  return request({
    url: '/v2/news/page',
    method: 'get',
    params: {
      limit,
      page,
      is_del,
    },
  })
}

// 获取新闻详情
export function newDetail(id) {
  return request({
    url: '/v2/news/one',
    method: 'get',
    params: {
      id,
    },
  })
}

// 获取上一项新闻
export function prevNew(id) {
  return request({
    url: '/v2/news/pre',
    method: 'get',
    params: {
      id,
    },
  })
}

// 获取下一项新闻
export function nextNew(id) {
  return request({
    url: '/v2/news/nex',
    method: 'get',
    params: {
      id,
    },
  })
}

// 获取分页艺人
export function pageArt(limit, is_del, page, type, is_recommend) {
  return request({
    url: '/v2/artist/page',
    method: 'get',
    params: {
      limit,
      is_del,
      page,
      type,
      is_recommend
    },
  })
}
// 获取指定数量的作品类型
export function ArtType(limit) {
  return request({
    url: '/v2/art_type/limit',
    method: 'get',
    params: {
      limit,
      order: 'sort',
      desc: 'asc',
      is_show: 1
    }
  })
}
// 获取到分页作品
export function pageWork(limit, is_del, page, val) {
  return request({
    url: '/v2/production/page',
    method: 'get',
    params: {
      limit,
      is_del,
      page,
      with: val,
    },
  })
}

// 获取作品详情
export function workDetail(id) {
  return request({
    url: '/v2/production/one',
    method: 'get',
    params: {
      id,
    },
  })
}

// 获取指定条数的作品
export function workLimit(data) {
  return request({
    url: '/v2/production/limit',
    method: 'get',
    params: data
  })
}
// 获取指定数量的作品类型
export function workType(limit) {
  return request({
    url: '/v2/pro_type/limit',
    method: 'get',
    params: {
      limit,
      order: 'sort',
      desc: 'asc',
      is_show: 1
    },
  })
}
// 获取所有招聘类型
export function reType(company_id) {
  return request({
    url: '/v2/jobType/noPage',
    method: 'get',
    params: { company_id },
  })
}

// 获取招聘信息
export function reMsg(type_id, is_online, company_id) {
  return request({
    url: '/v2/job/all',
    method: 'get',
    params: {
      type_id,
      is_online,
      company_id,
    },
  })
}

// 艺人招聘提交
export function reSubmit(data) {
  return request({
    url: '/v2/recruit/add',
    method: 'post',
    data,
  })
}

// 联系我们提交
export function usSubmit(data) {
  return request({
    url: '/v2/feedback/add',
    method: 'post',
    data,
  })
}

// 获取公司信息
export function ComMsg() {
  return request({
    url: '/v2/company/all',
    method: 'get',
  })
}

// 获取logo
export function Logo(type) {
  return request({
    url: '/web/home/getBanner',
    method: 'get',
    params:{
      type:type
    }
  })
}

// 获取荣誉证书
export function Honor() {
  return request({
    url: 'v2/honor/all',
    method: 'get',
  })
}

// 获取业务范围
export function Scope(type) {
  return request({
    url: '/v2/sundry/all',
    method: 'get',
    params: { type },
  })
}

export function brandCase(type) {
  return request({
    url: '/web/brand/getBrandBanner',
    method: 'get',
    params: type,
  })
}

export function brandCooperation() {
  return request({
    url: '/web/brand/getBrand',
    method: 'get',
  })
}

export function history_honor_List() {
  return request({
    url: '/web/about/getHistory',
    method: 'get',
  })
}

export function honor_List() {
  return request({
    url: '/web/about/getHonor',
    method: 'get',
  })
}

export function index_List() {
  return request({
    url: '/web/home/fansAndDesc',
    method: 'get',
  })
}

export function Home_News_list(data) {
  return request({
    url: '/web/home/getNews',
    method: 'get',
    params:data
  })
}
// 华星在这里
export function getCompany(data) {
  return request({
    url: '/web/home/getCompany',
    method: 'get',
    params:data
  })
}

export function Index_Model_list(data) {
  return request({
    url: '/web/home/getIndexModel',
    method: 'get',
    params:data
  })
}
// 新闻详情
export function showNews(data) {
  return request({
    url: '/web/home/showNews',
    method: 'get',
    params:data
  })
}

export function addhxRecruit(data) {
  return request({
    url: '/web/Recruit/hxRecruit',
    method: 'post',
    data:data
  })
}

export function Government_Survey_list(data) {
  return request({
    url: '/web/multielement/getGovernmentSurvey',
    method: 'get',
    params:data
  })
}

export function Government_Survey_classApply(data) {
  return request({
    url: '/web/multielement/classApply',
    method: 'post',
    data:data
  })
}

export function School_Teach_list() {
  return request({
    url: '/web/multielement/getSchoolTeach',
    method: 'get',
  })
}

export function School_Cooperation_list() {
  return request({
    url: '/web/multielement/getSchoolCooperation',
    method: 'get',
  })
}
