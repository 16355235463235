<template>
  <div class='right-position' id='rightScrollbox' ref='scrollbox'>
    <div class='right_btn' @click='btnChange'  v-show='!isShow'>
      <img src='@/assets/btnImg.png'>
    </div>
    <div class='right_forms' v-show='isShow'>
      <div class='closed' @click='btnChange'>
        <i class='el-icon-close'></i>
      </div>
      <div class='enlist'>
        <img src='@/assets/enlist.png'>
      </div>
      <el-form ref="form" :model="form" :rules="rules">
        <div class='title_png'>
          <div class='left_title_png'>
            <img src='@/assets/twinkie.png'>
          </div>
          <div class='right_title_png'>
            <img src='@/assets/title.png'>
          </div>
        </div>
        <el-form-item prop="name">
          <div class='form-item_input'>
            <div class='form-item_input_left'>
              <div class='input_left_img'>
                <img src='@/assets/userName.png'>
              </div>
              <div style='width: 28px'>姓名</div>
            </div>
            <el-input v-model="form.name" size='mini'></el-input>
          </div>
        </el-form-item>
        <el-form-item prop="tel">
          <div class='form-item_input'>
            <div class='form-item_input_left'>
              <div class='input_left_img' style='width: 26px'>
                <img src='@/assets/tel.png' alt=''>
              </div>
              <div style='width: 28px'>电话</div>
            </div>
            <el-input v-model="form.tel" size='mini'></el-input>
          </div>
        </el-form-item>
        <el-form-item prop="weChat">
          <div class='form-item_input'>
            <div class='form-item_input_left'>
              <div class='input_left_img' style='width: 26px'>
                <img src='@/assets/weChat.png' alt=''>
              </div>
              <div style='width: 28px'>微信</div>
            </div>
            <el-input v-model="form.weChat" size='mini'></el-input>
          </div>
        </el-form-item>
        <el-form-item class='item_primary'>
          <div style='font-size: 14px;font-family: PingFang SC-Bold, PingFang SC;font-weight: bold;color: #333333;line-height: 30px'>加入类型</div>
          <el-radio-group v-model="form.type" style='flex;flex-flow: row nowrap;justify-content: space-between;'>
            <el-radio label="1" style='margin-bottom: 15px'>主播</el-radio>
            <el-radio label="2" style='position: relative;margin-bottom: 15px'>
              短视频达人
              <div class='sub_radio' v-if='form.type==2'>（要求账号粉丝基数10W+）</div>
            </el-radio>
            <el-radio label="3"  style='margin-bottom: 15px'>本地生活商家入驻</el-radio>
          </el-radio-group>
        </el-form-item>
        <div slot="tip" class="el-upload__tip">{{tip}}</div>
        <el-form-item>
          <div style='text-align: center'>
            <el-button type="primary" size='small' @click="submitForm('form')">提交</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import { addhxRecruit } from '@/api/banner'
  export default {
    name: 'recruitment',
    data(){
      return{
        isShow:true,
        form:{
          name:'',
          tel:'',
          weChat:'',
          type:'1'
        },
        tip:'',
        rules:{
          name:[{required: true, message: '请输入姓名', trigger: 'blur'}],
          tel:[{required: true, message: '请输入电话', trigger: 'blur'}],
          weChat:[{required: true, message: '请输入微信', trigger: 'blur'}]
        }
      }
    },
    mounted(){

    },
    methods:{
      handleScroll(e) {
        this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop || e.target.scrollTop;
        if (this.scrollTop > 200) {
          this.isShow = true
        }
        if(this.scrollTop < 200){
          this.isShow = false
        }
      },
      btnChange:function(){
        let data={
          name:'',
          tel:'',
          weChat:'',
          type:'1'
        }
        this.$set(this,'form',data)
        this.isShow=!this.isShow
      },
      submitForm:function(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let data={
              username:this.form.name,
              phone:this.form.tel,
              wechat:this.form.weChat,
              type:this.form.type
            }
            addhxRecruit(data).then(resp=>{
              if(resp.status == 200){
                // this.$message({
                //   type:'success',
                //   message:'操作成功！'
                // })
                let data={
                  name:'',
                  tel:'',
                  weChat:'',
                  type:'1'
                }
                this.$set(this,'form',data)
                this.isShow=!this.isShow
              }else {
                this.$set(this,'tip',resp.msg)
              }
            }).catch(error=>{

            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });

      }
    },
    created() {
      // window.addEventListener('scroll', this.handleScroll, true)
    },
    destroyed() {
      // window.removeEventListener('scroll', this.handleScroll, true)
    }
  }
</script>

<style lang='scss' scoped>
  .right-position{
    position: fixed;
    right: 0;
    top: 40%;
    z-index: 999;
  .right_btn{
    width: 86px;
    height: 294px;
    position: fixed;
    right: 0;
  img{
    width: 100%;
    height: 100%;
  }
  }
  .right_forms{
    padding: 10px 44px;
    padding-top: 20px;
    width: 370px;
    height: 400px;
    box-sizing: border-box;
    position: fixed;
    right: 0px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    background-color: #fff;

  }
  }
  .el-form-item{
    margin-bottom: 15px!important;
  }

  .form-item_input{
    width: 100%;
    box-sizing: border-box;
    background: #F5F2F2;
    padding: 0px 10px;
    display: flex;
    flex-flow: row nowrap;
    border: 1px solid #D54724;
    border-radius: 30px;
    align-items: center;
  .form-item_input_left{
    font-size: 14px;
    color: #ccc;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  .input_left_img{
    margin-right: 5px;
    width: 18px;
    height: 22px;
  img{
    width: 100%;
    height: 100%;
  }
  }
  }
  }
  .title_png{
    display: flex;
    flex-flow: row nowrap;
    /*align-items: center;*/
    justify-content: center;
  .left_title_png{
    width: 44px;
    height: 60px;
  img{
    width: 100%;
    height: 100%;
  }
  }
  .right_title_png{
    width: 146px;
    height: 30px;
  img{
    width: 100%;
    height: 100%;
  }
  }
  }
  .closed{
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 28px;
    color: #D54724;
  }
  .sub_radio{
    position: absolute;
    font-size: 16px;
    left: -5px;
    top: 15px;
    color: #D54724;
    /*transform: scale(0.8);*/
  }

  .item_primary{
    margin-left: -25px;
    margin-right: -25px;
  }
  .enlist{
    width: 116px;
    height: 24px;
    position: absolute;
    bottom: 125px;
    right: 15px;
  }
</style>
