<template>
  <div id='map'>
    <div class='head-info-item2' style='position:relative'>
      <img src='../assets/position.png' alt='' class='positions'>
      <div class='header-anchor'>
        <div style='margin: 30px 0 30px 0;text-align: center;'>
          <span>{{ $t('index.isHere') }}</span>
<!--          <img src='../assets/address-word.png' alt='' class='images address-word'>-->
        </div>
        <div class='map-info-list'>
          <div class='left-content-s'>
            <div class="content-data" v-for='(item, index) in address_list' :key='index'>
              <div>{{ item.title }}</div>
              <div>电话: {{ item.tel }}</div>
              <div>地址：{{ item.address }}</div>
            </div>
          </div>
          <div class='right-content'>
            <el-image style='width: 100%;;height:100%;border-radius: 0px 20px 20px 0px;' :src='company_map_img' lazy></el-image>
<!--            <div ref='mainContainer' class='map-container' id='mainContainer' style="height: 100%; width: 100%;border-radius: 0px 30px 30px 0px;"></div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '../../node_modules/echarts/map/js/china.js'
import china from '../../node_modules/echarts/map/json/china.json'
import { getCompany ,index_List} from '@/api/banner'
export default {
  name: 'mapChart',
  data(){
    return{
      address_list: [],
      company_map_img:''
    }
  },
  mounted() {
    this.get_company() // 华星在这里
    this.get_index_List()
  },
  methods: {
    get_index_List: function() {
      index_List().then(resp => {
        if (resp.status == 200) {
          console.log('6666666',resp.data.company_map_img)
          this.$set(this, 'company_map_img', resp.data.company_map_img ? resp.data.company_map_img :'')
        }
      }).catch(error => {

      })
    },
    // 华星在这里
    get_company: function() {
      getCompany().then(resp => {
        if(resp.status == 200) {
          this.$set(this, 'address_list', resp.data)
          this.$nextTick(()=>{
            this.getMapChart()
          })
        } else {
          this.$message(resp.data.msg)
        }
      }).catch(err => {
      })
    },
    getMapChart: function() {
      let arr = []
      let arr1 = []
      this.address_list.forEach(item => {
        arr.push({
          name: item.title,
          address: item.address,
          value: [item.precision, item.latitude]
        })
      })
      // 初始化echarts实例
      // var myEcharts = this.$echarts.init(document.getElementById('mainContainer'))
      // for(let i = 0 ; i < china.features.length; i ++) {
      //   arr1.push({
      //     name: china.features[i].properties.name,
      //     itemStyle: {
      //       // 隐藏地图
      //       normal: {
      //         borderWidth: .5, //区域边框宽度
      //         borderColor: '#666', //区域边框颜色
      //         borderType:'dotted', //虚线
      //         areaColor: '#F2F2F2', //区域颜色
      //       }
      //     }
      //   })
      // }
      // let dataTemp = arr
      // var option = {
      //   // backgroundColor: '#F2F2F2',//画布背景颜色
      //   geo: {
      //     map: 'china',
      //     label: {
      //       normal: {
      //         show: false //显示省份标签
      //       },
      //       emphasis: { //对应的鼠标悬浮效果
      //         show: false,
      //         textStyle: {
      //           color: '#fff'
      //         }
      //       }
      //     },
      //     roam: false,//是否开启鼠标缩放和平移漫游
      //     itemStyle: {//地图区域的多边形 图形样式
      //       normal: {//是图形在默认状态下的样式
      //         borderWidth: .5, //区域边框宽度
      //         borderColor: '#bdbdbd', //区域边框颜色
      //         // areaColor: '#F2F2F2', //区域颜色
      //       },
      //       emphasis: {//是图形在高亮状态下的样式,比如在鼠标悬浮或者图例联动高亮时
      //         borderWidth: .5,
      //         borderColor: '#bdbdbd',
      //         areaColor: '#fff',//2022-7-15
      //         label: { show: true },
      //       }
      //     },
      //     aspectScale: 0.75,
      //     zoom: 1.2,//地图缩放比例,默认为1
      //     // 这里是重点！！！
      //     regions: [
      //       {
      //         name: "南海诸岛",
      //         itemStyle: {
      //           // 隐藏地图
      //           normal: {
      //             opacity: 0, // 为 0 时不绘制该图形
      //           }
      //         },
      //         label: {
      //           show: false // 隐藏文字
      //         }
      //       },
      //       ...arr1
      //     ]
      //   },
      //   title: {  //标题样式
      //     text: '',
      //     x: 'center',
      //     textStyle: {
      //       fontSize: 18,
      //       color: '#fff'
      //     }
      //   },
      //   tooltip: {  //这里设置提示框
      //     show: false,
      //     textStyle: {
      //       color: "#D54724",
      //       fontSize: 16,
      //       padding: 30,
      //       align: "left",
      //       zIndex: 99
      //     },
      //     trigger: 'item',  //数据项图形触发
      //     backgroundColor: 'rgba(213, 71, 36, 0.1)',  //提示框浮层的背景颜色。
      //     triggerOn: "mousemove",
      //     // triggerOn: "click",
      //     enterable: true,
      //     formatter: function(params) {
      //       return  params.name
      //     }
      //   },
      //   visualMap: {//视觉映射组件
      //     show: false,
      //     top: 'center',
      //     left: 'left',
      //     min: 10,
      //     max: 500000,
      //     text: ['High', 'Low'],
      //     realtime: false,  //拖拽时，是否实时更新
      //     calculable: true,  //是否显示拖拽用的手柄
      //     inRange: {
      //       color: ['lightskyblue', 'yellow', 'orangered']
      //     }
      //   },
      //   series: [
      //     {
      //       type: 'map',
      //       map: 'china',
      //       roam: false,
      //       zoom: 1.23,
      //       center: [105, 36],
      //       borderColor: '#bdbdbd',
      //       geoIndex: 0,
      //       // aspectScale: 0.75, //长宽比
      //       showLegendSymbol: false, // 存在legend时显示
      //       label: {
      //         normal: {
      //           show: false
      //         },
      //         emphasis: {
      //           show: false,
      //           textStyle: {
      //             color: '#fff'
      //           }
      //         }
      //       },
      //       itemStyle: {
      //         normal: {
      //           areaColor: '#fff5f3',
      //           borderColor: '#bdbdbd',
      //           borderType:'dotted', //虚线
      //           borderWidth: 0.5
      //         },
      //         emphasis: {
      //           areaColor: '#fdcabd',
      //           shadowOffsetX: 0,
      //           shadowOffsetY: 0,
      //           shadowBlur: 5,
      //           borderWidth: 0,
      //           zIndex:0,
      //           shadowColor: 'rgba(189, 189, 189, 0.5)'
      //         }
      //       }
      //     //  layoutCenter: ['50%', '50%'],//位置
      //     // layoutSize:'554px',//大小
      //     },
      //     {
      //       name: '',
      //       type: 'custom', // series图表类型
      //       coordinateSystem: 'geo', // series坐标系类型
      //       animation: true,
      //       animationDelay: function (idx) {       // 图片动画延迟
      //         return idx * 50;
      //       },
      //       renderItem(params, api) {
      //         //具体实现自定义图标的方法
      //         return {
      //           type: "image",
      //           style: {
      //             image: require('@/assets/orientation.png'),
      //             x: api.coord([
      //               dataTemp[params.dataIndex].value[0],
      //               dataTemp[params.dataIndex].value[1]
      //             ])[0],
      //             y: api.coord([
      //               dataTemp[params.dataIndex].value[0],
      //               dataTemp[params.dataIndex].value[1]
      //             ])[1],
      //             width: 20,
      //             height: 28,
      //           },
      //           // styleEmphasis:{
      //           //   shadowColor: 'rgba(0, 0, 0, 0.1)',
      //           //   shadowBlur: 10
      //           // }
      //         };
      //       },
      //       symbolSize: function(val) {
      //         return val[2] / 10;
      //       },
      //       tooltip: {  //这里设置提示框
      //         show: true,
      //         textStyle: {
      //           color: "#D54724",
      //           fontSize: 16,
      //           padding: 30,
      //           align: "left",
      //           zIndex: 99
      //         },
      //         trigger: 'item',  //数据项图形触发
      //         backgroundColor: 'rgba(213, 71, 36, 0.1)',  //提示框浮层的背景颜色。
      //         triggerOn: "mousemove",
      //         // triggerOn: "click",
      //         enterable: true,
      //         formatter: function(params) {
      //           return  '<div>'+ params.name +'</div>' + '<div style="font-size:12px;">'+params.data.address+'</div>'
      //         }
      //       },
      //       data: dataTemp,
      //       z: 10
      //     },
      //   ]
      // }
      // myEcharts.setOption(option)
      // let  language=localStorage.getItem('locale')
      // window.addEventListener('resize', function () { myEcharts.resize();})
    },
  }
}
</script>

<style scoped lang='scss'>
.head-info-item2{
  position: relative;
  padding: 30px 0;
  .header-anchor{
    width: 63%;
    margin: auto;
    font-size: 26px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #333;
    position: relative;
    line-height: 30px;
    .images{
      width: 281px;
      position: absolute;
      left: 50%;
    }
    .address-word{
      width: 201px;
    }
  }
}
.map-container {
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  background-color: #F2F2F2;
}
</style>
