import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  // 走进华星
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: { title: '首页' }
  },
  // 直播
  // {
  //   path: '/live',
  //   name: 'live',
  //   component: () => import('../views/live.vue'),
  //   meta: { title: '直播' },
  // },
  // MCN
  // {
  //   path: '/MCN',
  //   name: 'MCN',
  //   component: () => import('../views/MCN.vue'),
  //   meta: { title: 'MCN' },
  // },
  {
    path: '/Business',
    name: 'Business',
    component: () => import('../views/Bussiness'),
    meta: { title: '公司业务' },
    children: [
      {
        path: '/Business/live',
        name: 'live',
        component: () => import('../views/live.vue'),
        meta: { title: '直播' },
      },
      {
        path: '/Business/MCN',
        component: () => import('../views/MCN.vue'),
        name: 'MCN',
        meta: { title: '短视频' }
      },
      {
        path: '/Business/game',
        name: 'game',
        component: () => import('../views/game.vue'),
        meta: { title: '游戏' },
      },
      {
        path: '/Business/Brand',
        name: 'Brand',
        component: () => import('../views/Brand.vue'),
        meta: { title: '品牌服务' },
      }
      ,
      {
        path: '/Business/LocalLife',
        name: 'LocalLife',
        component: () => import('../views/LocalLife.vue'),
        meta: { title: '本地生活' },
      },
      {
        path: '/Business/BusinessSchool',
        name: 'BusinessSchool',
        component: () => import('../views/BusinessSchool.vue'),
        meta: { title: '商学院' },
      }
    ]
  },
  // 电商
  // {
  //   path: '/shop',
  //   name: 'shop',
  //   component: () => import('../views/shop.vue'),
  //   meta: { title: '电商' },
  // },
  // 游戏
  // {
  //   path: '/game',
  //   name: 'game',
  //   component: () => import('../views/game.vue'),
  //   meta: { title: '游戏' },
  // },
  // {
  //   path: '/Brand',
  //   name: 'Brand',
  //   component: () => import('../views/Brand.vue'),
  //   meta: { title: '品牌服务' },
  // },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/aboutUs.vue'),
    meta: { title: '关于华星' },
  },
  // 新闻详情
  {
    path: '/newsDetails',
    name: 'newsDetails',
    component: () => import('../components/newsDetails'),
    meta: { title: '新闻详情',hideNavMenu: true },
  },
  {
    path: '/recruit',
    name: 'recruit',
    component: () => import('../views/recruit'),
    meta: { title: '职等你来'},

  },
  {
    path: '/diversifiedEmpowerment',
    name: 'diversifiedEmpowerment',
    component: () => import('../views/diversifiedEmpowerment'),
    meta: { title: '多元赋能'}

  }
]

export default routes
