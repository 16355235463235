import Vue from 'vue'
import App from './App.vue'
import routes from './router'
import VueRouter from 'vue-router'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import timelineCarousel from 'timeline-carousel'
import VuevideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
// import './static/siyuan/font.css'
const  $message = option=>{
  console.log(option)
  return Element.Message({
    ...option,
    offset:350,
    top:400
  })
}

['success','warning','info','error'].forEach(type=>{
  $message[type]= option=>{
    if(typeof option === 'string'){
      option={
        message:option,
        offset:350,
        top:400
      }
    }
    option.type = type
    return Element.Message(option)
  }
})
// import './styles/element-variables.scss'
// 引入flexible
import 'lib-flexible'
import VueAwesomeSwiper from 'vue-awesome-swiper'

/* 样式的话，我这里有用到分页器，就在全局中引入了样式 */
// 引入css
import 'swiper/css/swiper.css'
// 引入全局样式
import '@/style/global.css'
import echarts from 'echarts'
import i18n from './assets/lang'

Vue.prototype.$echarts = echarts
// 挂载VueAwesomeSwiper
Vue.use(VueAwesomeSwiper)
Vue.use(timelineCarousel)
Vue.use(VuevideoPlayer)
Vue.use(Element)
Vue.prototype.$message = $message
Vue.config.productionTip = false


let lang = document.documentElement.lang
let nowLang = localStorage.getItem('locale')
let setLang
if (nowLang) {
  setLang = nowLang
} else if (lang) {
  setLang = lang
} else {
  setLang = 'zh'
}

window.onload = async function() {
// 翻译全文
//   translator.translatePage(setLang)

// 翻译文本
// const txt = await translator.translateText("google", "zh", "I Love Code");

// 恢复
// setTimeout(() => {
//   translator.restorePage()
// }, 5000)
}

const router = new VueRouter({
  mode: 'hash',
  routes
})
new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})
// router.afterEach((to,from,next) => {
// window.scrollTo(0,0);
// 或
// window.scroll(0, 0);
// });
