<template>
   <div id="goTop" ref='scrollbox'>
     <div class="goTop" @click="goTop" v-show='goTopShow'>
       <i class="el-icon-caret-top goTopIcon"></i>
       <div>置顶</div>
    </div>
   </div>
</template>
<script>
export default {
  name: "goTop",
  data() {
    return {
      scrollTop: "",
      goTopShow: false
    }
  },
  watch: {
    scrollTop(val) {
      if (this.scrollTop > 300) {
        this.goTopShow = true;
      } else {
        this.goTopShow = false;
      }
    }
  },
  methods: {
    handleScroll(e) {
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop || e.target.scrollTop;
      if (this.scrollTop > 300) {
        this.goTopShow = true
      }
    },
    goTop() {
      let timer = null
      var _that = this
      cancelAnimationFrame(timer)
      timer = requestAnimationFrame(function fn() {
        if (_that.scrollTop > 0) {
          _that.scrollTop -= 250;
          document.body.scrollTop = document.documentElement.scrollTop = _that.scrollTop;
          timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(timer);
          _that.goTopShow = false;
        }
      });
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true)
  }
}
</script>

<style scoped>
.goTop {
  position: fixed;
  right: 40px;
  bottom: 60px;
  width: 40PX;
  height: 40PX;
  border-radius: 50%;
  background: #fff;
  padding: 5px;
  cursor: pointer;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  text-align: center;
  z-index: 222;
  font-size: 12px;
}
.goTop:hover .goTopIcon {
  color: #D54724;
}
.goTopIcon {
  font-size: 20px;
  color: #D54724;
}
 </style>
