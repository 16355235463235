export default {
  // message:{
  navbar: {
    Home: '首页',
    live: '直播',
    MCN: '短视频',
    shop: '电商',
    game: '游戏',
    Brand: '品牌服务',
    aboutUs: '关于华星',
    recruit:'职等你来',
    diversifiedEmpowerment:'多元赋能',
    Business:'公司业务',
    BusinessSchool:"商学院",
    LocalLife:"本地生活"
  },
  news:{
    time:"发布时间",
    anther:"来源：华星璀璨",
  },
  // 底部
  footer: {
    contentUs: '联系我们',
    cpAddress: '公司地址',
    Address: '地址：',
    gzUs: '关注我们',
    wechat: '官方公众号',
    Dy: '官方抖音',
    Sp: '官方视频号',
    WeiBo: '官方微博',
    KfPhone:'客服电话',
    weXin:'官方微信',
    severPhone:'服务电话',
  },
  // 首页
  index: {
    isHere: '华星在这里',
    HaxingLive: '华星直播',
    HuaxingMCN: '华星MCN',
    HuaxingGames: '华星游戏',
    Ecommerce: '华星电商'
  },
  LIVE: {
    headAnchor: '头部主播',
    hotAnchor: '热门主播',
    entertainmentStar: '娱乐星知道',
    brandText: '品牌/商品：',
    intention: '合作意向主播：',
    cooperationNeeds: '合作需求简介：',
    brandDirectSale: '是否为品牌直营: ',
    wechat: '微信号：',
    phone: '联系电话：',
    submitText: '提 交',
    businessCooperation: '商务合作',
    city: '所在城市：',
    rules_brandText: '请输入品牌/商品',
    rules_intention: '请输入合作意向主播',
    rules_cooperationNeeds: '请输入合作需求简介',
    rules_brandDirectSale: '请输入是否为品牌直营',
    rules_wechat: '请输入微信号',
    rules_phone: '请输入联系电话',
    rules_city: '请输入所在城市',
    rules_TiktokID: '请输入抖音ID(视频号)',
    rules_age: '请输入年龄',
    rules_sex: '请输入性别',
    rules_style: '请输入风格类型',
    anchorRecruitment: '主播招募',
    TiktokID: '抖音ID(视频号)',
    sex: '性别：',
    age: '年龄：',
    style: '风格类型：',
    cooperativeBrand: '合作品牌',
    Zhaijid: '宅机弟自孵化账号',
    headMaster: '头部达人',
    hotMaster: '热门达人',
    HighCases: '优质广告案例',
    PopularVideo: '爆款视频',
    AdvertisingHit: '广告爆款达人',
    TalentRecruitment: '达人招募',
    broadcastingPlatform: '直播平台：',
    LiveAccount: '直播账号：',
    expertise: '擅长领域：',
    broadcastResults: '曾经直播成绩简述:',
    rules_platform: '请输入直播平台',
    rules_accout: '请输入直播账号',
    rules_good: '请输入擅长领域',
    liveId: '抖音 ID',
    TiktokFans: '抖音粉丝量',
    TiktokLikes: '抖音点赞量',
    strictSelection: '华星严选',
    PopularCases: '爆款案例',
    RecentInformation: '近期资讯',
    moreNews: '更多新闻',
    noMore: '没有更多了',
    newsStar: '华星最新动态',
    LearnMore: '了解更多',
    LearnHuaxing: '了解华星',
    BrightChineseStars: '华星璀璨',
    signedArtist: '签约艺人',
    ServiceArtist: '全网服务艺人',
    NumberOfFans: '全网粉丝量',
    postion:'应聘职位:',
    name:'姓名：',
    filling:'信息填写',
    email:'邮箱：',
    rules_email:'请输入邮箱',
    rules_position:'请输入职位',
    rules_name:'请输入姓名',
    rules_brand:'请输入品牌名称',
    rules_MerchantScale:'请输入商家规模',
    Roster:'学员名单：',
    rules_Roster:'请输入学员名单',
  },
  Local:{
    brand_name:'品牌方/店名：',
    MerchantScale:'商家规模（含区域）：'
  },
  game: {
    GameMasters: '游戏达人',
    QualityCases: '优质案例（游戏商务类）',
    ClickView: '点击查看',
    RecentNews: '近期资讯',
    GameRecruitment: '游戏主播招募',
    formPT: '目前所在短视频平台：',
    formGame: '擅长游戏：',
    formFans: '目前粉丝数：',
    formCZ: '是否能独立创作视频：',
    titleMCN: '全国头部游戏MCN机构',
    contentMCN: '2018年成为企鹅电竞、抖音游戏官方的内容指定供应商。与50多家游戏开发商及游戏IP达成合作。拥有游戏KOL3000+，自主孵化了小宇-游戏砖家、骚傲、狂战神奥拉蛋等多个IP，内容涵盖游戏推荐、游戏测评、游戏直播、高光时刻、热门事件等，为多个短视频平台提供内容与服务。与腾讯广告在微信视频号互选平台达成战略合作关系。',
    rulePT: '请输入目前所在短视频平台',
    ruleGame: '请输入擅长游戏',
    ruleFans: '请输入目前粉丝',
    ruleCZ: '请输入是否能独立创作视频'
  },
  Brand: {
    BrandService: '品牌服务',
    BrandContent: '打造短视频平台服务生态 服务近百家品牌客户全心全意服务于品牌客户，将品牌客户需求形成供应闭环，提供全平台营销整合策划，打造品牌IP化形象，形成全网经典品牌口碑，点爆互联网。其部门下设两支主要业务板块，代理商板块与代运营板块，作为抖音、快手核心代理商，为品牌进行产品推广，建立优质官方店铺、短视频账号等，并串联公司电商带货、效果直播、店铺直播等多业务板块，形成推广闭环，专注短视频平台业务发展，综合性高效服务，提供一站式省心业务模式。',
    cooperationCases: '优质合作案例',
    CooperativeBrands: '合作品牌'
  },
  about: {
    hxTitle: '华星璀璨',
    hxTitleSup: '华星总部产业园',
    hxTitleEn: 'Huaxing Headquarters Industrial Park',
    hxYQ: '园区简介',
    hxYQContent: '华星璀璨成立于2018年，是“华星兄弟”旗下独立品牌全资子公司。目前公司大力发展直播、MCN、电商、游戏、品牌服务等五大业务，涵盖抖音、视频号、快手、斗鱼、陌陌、腾讯NOW等多个新媒体平台，是全国头部直播经纪公司、MCN服务机构、电商直播服务商。曾荣获成都市新经济百强企业、独角兽孵化企业、中国演出行业协会网络表演分会常务理事单位等。',
    hxYQContentE: '华星璀璨总部设立于成都东郊记忆互联网创意产业园，团队规模超600人。在北京、杭州、攀枝花等全国多个省市设立直营公司，不断突破发展，形成全国性经营业务的服务型企业。华星璀璨服务艺人超10万+，拥有众多头部、优质签约艺人，艺人全网粉丝量超10亿+，视频总点赞量超百亿+，视频总播放量超千亿+。作为优质内容供应商、艺人服务商，目前已与全国各大短视频、新媒体平台以及多个知名品牌达成战略合作。',
    hxYQContentT: '未来，华星璀璨也将继续扩大业生态产业发展，完善服务体系搭建，专注于做综合性、新经济、新传媒优质服务型的国际化企业。',
    lOne: '1栋',
    lOneName: '总部行政大楼',
    lTwo: '2栋',
    lTwoName: '商务内容',
    lThree: '4栋a',
    lThreeName: '行政接待',
    lFour: '4栋b',
    lFourName: '人事接待',
    lFive: '5栋',
    lFiveName: '直播业务',
    lSix: '6栋',
    lSixName: '直播间',
    lSeven: '7栋',
    lSevenName: '电商-直播间',
    lEight: '8栋',
    lEightName: '游戏业务',
    lNine: '10栋',
    lNineName: '电商+本地生活+直播业务',
    hxFZ: '发展历程',
    hxRY: '华星荣耀',
    hxNews: '华星新闻',
    hxMore: '更多新闻',
    hxNoMore: '没有更多了'
  },
  recruit:{
    join:'JOIN US',
    visit:'虚位以待',
    HuaxingCulture:'华星文化',
    signUp:'点击报名'
  }
  // }
}
