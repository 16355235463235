<template>
  <div class='footer'>
    <div class='contactUs'>
      <div class='contentUS_list'>
        <div class='_span_title'>{{ $t('footer.contentUs') }}</div>
        <div class='flex_span'>
          <div class='flex_span_item'>{{$t('footer.severPhone')}}：{{ dataJson && dataJson.sever_phone ? dataJson.sever_phone :
            '' }}
          </div>
          <div class='flex_span_item' translate='yes'>{{$t('footer.KfPhone')}}：{{ dataJson && dataJson.kefu_phone ? dataJson.kefu_phone : '' }}</div>
          <div class='flex_span_item'>{{$t('footer.weXin')}}：{{ dataJson && dataJson.wechat ? dataJson.wechat : '' }}</div>
        </div>
      </div>
      <div class='contentUS_list'>
        <div class='_span_title'>{{ $t('footer.cpAddress') }}</div>
        <div class='flex_span'>
          <div class='flex_span_item'>{{ $t('footer.Address') }}{{ dataJson && dataJson.address ? dataJson.address : ''
            }}
          </div>
          <!--          <div class='flex_span_item'>服务电话：028-83287306</div>-->
        </div>
      </div>
      <div class='contentUS_list'>
        <div class='_span_title'>{{ $t('footer.gzUs') }}</div>
        <div class='flex_span' style='margin-top: 16px'>
          <div class='WeChat_item'>
            <img :src=dataJson.official_accounts_img />
            <div>{{ $t('footer.wechat') }}</div>
          </div>
          <div class='WeChat_item'>
            <img :src=dataJson.douyin_img />
            <div>{{ $t('footer.Dy') }}</div>
          </div>

          <div class='WeChat_item'>
            <img :src=dataJson.shipinhao_img />
            <div>{{ $t('footer.Sp') }}</div>
          </div>

          <div class='WeChat_item'>
            <img :src=dataJson.weibo_img />
            <div>{{ $t('footer.WeiBo') }}</div>
          </div>
          <!--          <div class='WeChat_item'>-->
          <!--            <img :src=dataJson.enterprise_wechat_img />-->
          <!--            <div>企业微信</div>-->
          <!--          </div>-->
          <!--          <div class='WeChat_item'>-->
          <!--            <img :src=dataJson.wechat_img />-->
          <!--            <div>官方微信</div>-->
          <!--          </div>-->


        </div>
      </div>
      <div class='copyRight2'>
        <div class='yes-copyRight'>
          <span> COPYRIGHT @ 成都华星兄弟文化传媒有限公司</span><img src='../assets/ga.png'/><div translate='yes'><a target='_blank' href='https://beian.mps.gov.cn/#/query/webSearch?code=51010802031873' rel="noreferrer" style='color: #0087ff;text-decoration: none'>川公网安备51010802031873</a><a target='_blank' href='https://beian.miit.gov.cn/' rel="noreferrer" style='color: #0087ff;margin-left: 8px;text-decoration: none'>蜀ICP备17035091号-1</a></div>
        </div>

      </div>
<!--      <div class='copyRight'>-->

<!--        <div translate='yes'><a target='_blank' href='https://beian.miit.gov.cn/' style='color: #666;text-decoration: none'>{{ dataJson && dataJson.beianhao ? dataJson.beianhao : '' }}</a></div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import { index_List } from '@/api/banner'

export default {
  name: 'footerTail',
  data() {
    return {
      dataJson: {}
    }
  },
  mounted: function() {
    this.get_index_List()
  },
  methods: {
    get_index_List: function() {
      index_List().then(resp => {
        if (resp.status == 200) {
          this.$set(this, 'dataJson', resp.data)
        }
      }).catch(error => {

      })
    }
  }
}
</script>

<style lang='scss' scoped>
.footer {
  width: 100%;
  height: 510px;
  background-image: url("../assets/footer.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  .contactUs {
    width: 810px;
    min-width: 500px;
    box-sizing: border-box;
    padding-top: 41px;
    position: relative;

    .contentUS_list {
      margin-bottom: 20px;

      ._span_title {
        font-size: 26px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #333333;
        line-height: 50px;
      }

      .flex_span {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        .flex_span_item {
          font-size: 16px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #666666;
          line-height: 30px;
        }
      }

    }

    .copyRight {
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: 10px;
      font-size: 12px;
    }
  }
}

.WeChat_item {
  /*width: 116px;*/
  height: 92px;
  text-align: center;

  img {
    width: 70px;
    height: 70px;
  }

  div {
    font-size: 16px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #D54724;
  }
}
.copyRight2{
  width: 100%;
  position: absolute;
  bottom: 15px;
  text-align: center;
  .yes-copyRight{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    img{
      width: 20px;
      height: 20px;
      margin-right: 8px;
      margin-left: 8px;
    }
  }
a{
  color: #0087ff;
}
}
</style>
