<template>
  <div :class="[activeIndex == '/' ? 'backgroundHeader' : '', 'header w']">
    <el-row style='width: 100%;height: 100%;'>
      <el-col :span='7' style='height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;'>
        <div class='logo'>
          <img v-if="activeIndex == '/'" src='../../src/assets/cuicanLogo_2.png' alt />
          <img v-else src='../../src/assets/cuicanlogo.png' alt />
        </div>
      </el-col>
      <el-col :span='8' style='justify-content: flex-end'></el-col>
      <el-col :span='9' style='justify-content: center'>
        <div :class="[activeIndex == '/' ? 'nav-s' : '', 'nav']" style='height:100%;line-height: 80px'>
          <ul style='height:100%;line-height: 80px;position: relative'>
            <li v-for='(item,index) in routes' :key='index' @mouseover='changeHover(item)' @mouseleave='changeLeave' v-if='!item.meta.hideNavMenu'
                :class="activeIndex == item.path ? 'activeColor' : ''">
<!--              <router-link :to='item.path'>-->
              <router-link :to='item.hasOwnProperty("children") ? item.children[0].path : item.path'>
                <p translate='no' style='position: relative'>
                  <span>{{ $t(`navbar.${item.name}`, { title: item.meta.title }) }}</span>
                  <span class='line_s'></span>
                </p>
              </router-link>
              <ul v-if='item.hasOwnProperty("children")' class='child-ren' :style='{"display":show ? "block" : "none"}'>
                <li  v-for='(itm,idx) in item.children' :key='idx'>
                  <router-link :to='itm.path'  :class="activeIdx == itm.path ? 'itmClass' : ''">
                    <p translate='no'>
                      <span>{{ $t(`navbar.${itm.name}`, { title: itm.meta.title }) }}</span>
<!--                      <span class='line_s'></span>-->
                    </p>
                  </router-link>
                </li>
              </ul>
              <!-- <div
                :class="activeIndex == index ? 'activeColor' : ''"
                @click="changeColor(index,item.url)"
              >{{item.name}}</div>-->
            </li>
          </ul>
        </div>
      </el-col>
<!--      <el-col :span='7' style='justify-content: flex-end'>-->
        <div style='width: 30px;height: 1px;'></div>
        <!--        <el-dropdown trigger='click' @command='changeIanguage'>-->
        <!--             <span class='el-dropdown-link'>-->
        <!--               {{ language }}<i class='el-icon-arrow-down el-icon&#45;&#45;right'></i>-->
        <!--             </span>-->
        <!--          <el-dropdown-menu slot='dropdown'>-->
        <!--            <el-dropdown-item command='zh'>中文</el-dropdown-item>-->
        <!--            <el-dropdown-item command='en'>English</el-dropdown-item>-->
        <!--            <el-dropdown-item command='th'>ภาษาไทย</el-dropdown-item>-->
        <!--          </el-dropdown-menu>-->
        <!--        </el-dropdown>-->
<!--      </el-col>-->
    </el-row>


    <!--    <div class='lang-item'>-->
    <!--      <span :class="language=='zh'?'isColor':''" @click="changeIanguage('zh')">中文</span>/-->
    <!--      <span :class="language=='en'?'isColor':''" @click="changeIanguage('en')">EN</span>/-->
    <!--      <span :class="language=='Thai'?'isColor':''" @click="changeIanguage('Thai')">Thai</span>-->
    <!--    </div>-->


    <div class='topBack'>
      <top></top>
    </div>
    <div class='topBack'>
      <recruitment></recruitment>
    </div>
  </div>
</template>

<script>
import routes from '../router'
import { Nav, Logo } from '@/api/banner'
import top from '../views/top'
import recruitment from './recruitment'
// import translator from '@sternelee/translator-worker'
export default {
  name: 'navItem',
  components: {
    top,
    recruitment
  },
  directives: {},
  data() {
    return {
      List: [],
      routes: [],
      activeIndex: '/',
      activeIdx:'',
      logo: {},
      language: '',
      show:false
    }
  },
  created() {
    // this.getAllBanner()
    let language = localStorage.getItem('locale')
  },
  mounted() {
    // console.log('-------------routes', routes)
    this.routes = routes
    // this.getAllNav()
    // this.getlogo()
    if (localStorage.getItem('locale') == 'en') {
      this.language = 'English'
    } else if (localStorage.getItem('locale') == 'th') {
      this.language = 'ภาษาไทย'
    } else {
      this.language = '中文'
    }
  },
  watch: {
    $route() {
      this.activeIndex = this.$route.path
      if(this.$route.path.includes('Business')){
        this.activeIndex = '/Business'
        this.activeIdx = this.$route.path
      }else {
        this.activeIdx=''
      }
      this.show=false
      // console.log('activeIndex',this.activeIndex)
      // console.log('activeIdx',this.activeIdx)
      // setTimeout(() => { //路由跳转
      //     this.$refs.menu.activeIndex = i
      // }, 100)
    },
    language: {
      immediate: true,
      deep: true,
      handler: function(val) {
        console.log(345345, val)
      }
    }
  },
  methods: {
    changeLeave:function(){
      this.show=false
    },
    changeHover:function(item){
      if(item.hasOwnProperty("children")){
        this.show=true
      }else{
        this.show=false
      }
    },
    // 改变语言
    changeIanguage(language) {
      if (language == 'en') {
        this.$set(this, 'language', '英语')
      } else if (language == 'th') {
        this.$set(this, 'language', '泰语')
      } else {
        this.$set(this, 'language', '中文')
      }
      localStorage.setItem('locale', language)
      this.$i18n.locale = language //这个代码负责实时切换语言
      location.reload()
      // let m = {
      //   live: {
      //     sadfs: '头部主播'
      //   }
      // }
      // let textInner = this.$t('navbar');
      // this.$i18n.setLocaleMessage('zh', {...this.$i18n.getLocaleMessage('zh'), ...m })
      // console.log(this.$i18n.getLocaleMessage('zh'), textInner)
      // this.$i18n.setLocaleMessage(e, res.data);
    },
    link: function(url, val) {
      console.log(url, val)
      this.activeIndex = val
      this.$router.push({
        path: url
      })
    },
    changeColor(index, url) {
      console.log(index)
      this.$router.push(url)
      this.activeIndex = index
    },
    // 获取到导航
    getAllNav() {
      Nav('sort', 1)
        .then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.List = res.data.data
          }
        })
        .catch()
    },
    // 获取logo
    getlogo() {
      Logo()
        .then(res => {
          console.log('-----------------------99', res)
          if (res.data.code == 200) {
            this.logo = res.data.data
            sessionStorage.setItem('developmentCourse', res.data.data.development_course)
          }
        })
        .catch()
    }
  }
}
</script>

<style lang='scss' scoped>

pre {
  font-family: none !important;
  white-space: nowrap !important;
}

ul {
  list-style: none;
}

/* 顶部导航 */
.header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  //background: linear-gradient(-90deg, #E84B24, #FFFFFF);
  //padding-right: 20px;
  color: #333
}

.backgroundHeader {
  //background: linear-gradient(-90deg, #E84B24, #FFFFFF);
  position: absolute;
  width: 100%;
  z-index: 11;
}

.logo {
  width: 186px;
  height: 42px;
  /* border: 1px solid red; */
  margin-left: 58px;
  margin-right: 48px;
  //margin-top:10px;
}

.logo img {
  width: 100%;
  /*height: 100%;*/
}

.nav ul {
  display: flex;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333;
  height: 100%;
}

.nav ul li {
  padding: 0px 20px;
  //margin-right: 54px;
  height: 80px;
  color: #333333;
  line-height: 80px;
  cursor: pointer;
}

.nav ul li:hover {
  color: #e44720;
}

.activeColor .line_s {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #e44720;
  position: absolute;
  bottom: 27px;
}

.lang-item {
  padding-right: 10px;

  span {
    cursor: pointer;
  }
}

.isColor {
  color: #fff;
}

.topBack {
  position: absolute;
}

.header .el-dropdown {
  width: 115px;
}

.header .el-dropdown-link {
  cursor: pointer;
  color: #333;
  display: flex;
  align-items: center;
}

.backgroundHeader .el-dropdown-link {
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
}

.el-col-8 {
  height: 100%;
  display: flex;
  align-items: center;
}
  .child-ren{
    position: absolute;
    top:60px;
    left: 75px;
    width: 110px;
    background-color:#fff;
    display: flex;
    height:auto!important;
    z-index: 2999;

    flex-flow: column;

    li{
      padding-left: 0;
      height: 36px!important;
      line-height: 36px!important;

      /*height: 36px;*/
      a{
        text-align: center;
        color: #333!important;
        line-height: 36px!important;

      }
    .itmClass{
      color: #e44720!important;
    }
    }
    li a:hover {
      color: #e44720!important;
    }
  }

</style>
